import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PricingTableSection extends Component{
	render(){
		return(
			<>
				<section className="section-area bg-white section-sp1">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Kainininkas</h6>
							<h2 className="title mb-0">ORIENTACINĖS MŪSŲ PASLAUGŲ KAINOS</h2>
							<p>Žinoma, skirtingų modelių remonto kainos gali skirtis. Pvz. vienam automobiliui pakeisti aušinimo skysčio siurblį laisvai galima pasikėlus automobilio kapotą, kitas keičiasi pakėlus automobilį, o dar kitiems modeliams, tam kad prieiti prie aušinimo siublio reikia, nuimti priekinį buferį ir dar daug kitų papildomų dalių. Suprantama, kad pirmu atveju remontas uztruks 2 val, trečiu atveju apie 6 val. </p>
						</div>
						<div className="pricingtable-row">
							<div className="row">
								<div className="col-xl-3 col-sm-6 mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-price"> 
													<span className="priceing-doller">EUR </span>
													<span className="pricingtable-bx">25.00</span>
												</div>
												<div className="pricingtable-title">
													<h4>Tepalų keitimas</h4>
													<p><i className="la la-clock"></i> 1 Valanda</p>
													<p><i className="la"></i> Machanikas</p>
												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Apsauginio dugno dalių nuėmimas</li>
												<li><i className="fa fa-check"></i> Patikta ar nėra tepalų nuotėkio</li>
												<li><i className="fa fa-check"></i> Seno tepalo išleidimas</li>
												<li><i className="fa fa-check"></i> Seno filtro nuėmimas ir pakeitimas nauju</li>
												<li><i className="fa fa-check"></i> Tepalo pakeitimas</li>
												<li><i className="fa fa-check"></i> Patikra ar nėra tepalo nuotėkio po remonto</li>
												<li><i className="fa fa-check"></i> Oro filtro pakeitimas</li>
											</ul>
											<div className="pricingtable-footer"> 
												<Link to="/booking" className="btn btn-primary">Užsakyti</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 mb-30">
									<div className="pricingtable-wrapper style1 pricingtable-highlight">
										<div className="pricingtable-tag">
											most used
										</div>
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-price"> 
													<span className="priceing-doller">EUR </span>
													<span className="pricingtable-bx">50.00</span>
												</div>
												<div className="pricingtable-title">
													<h4>Padangų montavimas, balansavimas</h4>
													<p><i className="la la-clock"></i> 2 valandos</p>
													<p><i className="la"></i> Machanikas</p>
												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Senų padangų nuėmimas</li>
												<li><i className="fa fa-check"></i> Senų padangų išmontavimas</li>
												<li><i className="fa fa-check"></i> Ratlankių patikra</li>
												<li><i className="fa fa-check"></i> Naujų padangų sumontavimas</li>
												<li><i className="fa fa-check"></i> Ratlankių balandsavimas</li>
												<li><i className="fa fa-check"></i> Naujų ratlankių sumontavimas ant automobilio</li>
											{/*	<li><i className="fa fa-check"></i> Select</li>*/}
											</ul>
											<div className="pricingtable-footer"> 
												<Link to="/booking" className="btn btn-primary">Užsakyti</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-price"> 
													<span className="priceing-doller">EUR </span>
													<span className="pricingtable-bx">75.00</span>
												</div>
												<div className="pricingtable-title">
													<h4>Stabdžių diskų, kaladėlių keitimas</h4>
													<p><i className="la la-clock"></i> 3 valandos</p>
													<p><i className="la"></i> Machanikas</p>
												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Ratų nuėmimas</li>
												<li><i className="fa fa-check"></i> Stabdžių sistemos patikra</li>
												<li><i className="fa fa-check"></i> Kaladėlių nuėmimas</li>
												<li><i className="fa fa-check"></i> Stabdžių diskų nuėmimas</li>
												<li><i className="fa fa-check"></i> Naujų stabdžių diskų sumontavimas</li>
												<li><i className="fa fa-check"></i> Naujų kaladėlių sumontavimas</li>
												<li><i className="fa fa-check"></i> Ratų uždėjimas</li>
											</ul>
											<div className="pricingtable-footer"> 
												<Link to="/booking" className="btn btn-primary">Užsakyti</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-price"> 
													<span className="priceing-doller">EUR </span>
													<span className="pricingtable-bx">45.00</span>
												</div>
												<div className="pricingtable-title">
													<h4>Kompiuterinė diagnostika</h4>
													<p><i className="la la-clock"></i> 1-2 valandos</p>
													<p><i className="la"></i> Inžinierius</p>
												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Kompiuterinė diagnostika</li>
												<li><i className="fa fa-check"></i> Kalidų nustatymas</li>
												<li><i className="fa fa-check"></i> Klaidų pagal kodus paieška</li>
												<li><i className="fa fa-check"></i> Sugedusių dalių patikra</li>
												<li><i className="fa fa-check"></i> Kalidingų klaidų ištaisymas</li>
		{/*										<li><i className="fa fa-check"></i> 30 days Listing</li>
												<li><i className="fa fa-check"></i> 24/7 Support</li>
												<li><i className="fa fa-check"></i> Select</li>*/}
											</ul>
											<div className="pricingtable-footer"> 
												<Link to="/booking" className="btn btn-primary">Užsakyti</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default PricingTableSection;