import React, { Component } from 'react';

class ScheduleServiceSection extends Component{
	render(){
		return(
			<>
				<section className="section-area bg-primary form-card">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-3 align-self-center">
								<div className="form-head text-white mb-md-30">
									<h3 className="title">REZERVUOKITE VIZITĄ</h3>
									<h6 className="mb-0"><span className="fw4 mr-1">arba skambinkite</span> <br/><br/> +370(654)47643</h6>
								</div>
							</div>
							<div className="col-lg-9">
								<form>
									<div className="row sp5">
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Vardas*"/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Email"/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Telefonas*"/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<select className="form-select form-control">
													<option>Paslaugos tipas*</option>
													<option>Tepalų keitimas</option>
													<option>Stabdžių remontas</option>
													<option>Padangų montavimas, balansavimas</option>
													<option>Važiuoklės remontas</option>
													<option>Smulkūs kėbulo darbai</option>
													<option>Virinimas</option>
													<option>Kompiuterinė diagnostika</option>
													<option>Kita</option>
												</select>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Trumpas problemos aprašymas"/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<button type="submit" className="btn btn-warning w-100">PATEIKTI UŽKLAUSĄ</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default ScheduleServiceSection;