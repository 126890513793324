import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr4.jpg";

class Booking extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>REZERVACIJA</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Pagrindinis</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Rezervacija</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-30 mb-md-50">
									<form className="booking-form">
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">AUTOMOBILIO METAI</h6>
													<div className="input-group">
														<input type="text" className="form-control" />
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">AUTOMOBILIO MARKĖ</h6>
													<div className="input-group">
														<input type="text" className="form-control"/>
														{/*<select className="form-control">
															<option value="" selected="selected">Choose...</option>
															<option value="General Motors">General Motors</option>
															<option value="Land Rover">Land Rover</option>
															<option value="Lexus">Lexus</option>
															<option value="Lincoln">Lincoln</option>
															<option value="Mazda">Mazda</option>
															<option value="Mercury">Mercury</option>
															<option value="Nissan">Nissan</option>
															<option value="Renault">Renault</option>
															<option value="Plymouth">Plymouth</option>
															<option value="Rover">Rover</option>
															<option value="Saab">Saab</option>
														</select>*/}
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">AUTOMOBILIO MODELIS</h6>
													<div className="input-group">
														<input type="text" className="form-control"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">PASIRINKITE NORIMAS PASLAUGAS</h6>
													<div className="row">
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing1"/>
																<label className="form-check-label" for="customControlAutosizing1">Tepalų keitimas</label>
															</div>
														</div>
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing2"/>
																<label className="form-check-label" for="customControlAutosizing2">Stabdžių remontas</label>
															</div>
														</div>
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing3"/>
																<label className="form-check-label" for="customControlAutosizing3">Kompiuterinė diagnostika</label>
															</div>
														</div>
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing4"/>
																<label className="form-check-label" for="customControlAutosizing4">Aušinimas Vėdinimas</label>
															</div>
														</div>
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing5"/>
																<label className="form-check-label" for="customControlAutosizing5">Padangų montavimas, balansavimas</label>
															</div>
														</div>
														<div className="col-md-12 col-lg-4">
															<div className="form-check custom-checkbox mb-10">
																<input type="checkbox" className="form-check-input" id="customControlAutosizing6"/>
																<label className="form-check-label" for="customControlAutosizing6">Kitas gedimas</label>
															</div>
														</div>
													</div>										
												</div>										
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">ASMENINĖ INFORMACIJA</h6>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" className="form-control" placeholder="Vardas" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="email" placeholder="Email" className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" placeholder="Telefono numeris" className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="subject" type="text" required="" className="form-control"  placeholder="Paslaugos pavadinimas"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" required=""  placeholder="Žinutė"></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg"> Siųsti</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-4 col-md-5 mb-30">
									<aside className="sticky-top">
										<div className="widget">
											<h5 className="widget-title">Naudingos nuorodos</h5>
											<ul className="service-menu style-1">
												<li><Link to="/">Apie mus <i className="las la-question-circle"></i></Link></li>
												<li><Link to="/service-1">Mūsų paslaugos <i className="las la-cog"></i></Link></li>
										{/*		<li><Link to="/job-career">Job & Career<i className="las la-user-plus"></i></Link></li>*/}
												<li><Link to="/pricing-plan">Orientacinės kainos<i className="las la-table"></i></Link></li>
												{/*<li><Link to="/team">Mūsų komanda<i className="las la-user-tie"></i></Link></li>*/}
											{/*	<li className="active"><Link to="/booking">Booking<i className="lab la-wpforms"></i></Link></li>*/}
												<li><Link to="/faq-1">Patarimai<i className="las la-quote-left"></i></Link></li>
											</ul>
										</div>
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Booking;