import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg2.png";

class FeatureOneSection extends Component {
    render() {
        return (
            <>
                <section className="section-area bg-gray section-sp1" style={{
                    backgroundImage: "url(" + bg2 + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right top"
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-fuel-1"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Tepalų keitimas</h5>
                                        <p>Sintetinius variklio tepalus reikia keisti kas 15 000 tūkstančių
                                            kilometrų.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-brake"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Stabdžių remontas</h5>
                                        <p>Ramus važiavimas mieste turėtų užtikrinti stabdžių kaladėlių atsparumą
                                            mažiausiai 30-50 tūkstančių km.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-car-4"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Paruošimas technikinei apžiūrai</h5>
                                        <p>Mes atliksime automobilio patikrą ir paruošime ją technikinei apžiūrai.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-check-2"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Kompiuterinė diagnostika</h5>
                                        <p>Automobilio kompiuterinė diagnostika dažniausiai parodo kur yra problema, o
                                            prieš keičiant detalę papildomai patikriname</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-battery-2"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Akumuliatoriai</h5>
                                        <p>Jei automobilis sunkiai ir ilgai kuriasi, mes atliksime visus reikalingus
                                            matavimus ir nustatysime priežastis.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-paint"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Virinimo paslaugos</h5>
                                        <p>Paprastai pirmas indikatorius kad duslintuvas prakiuro ar prarūdijo yra
                                            garsus išmetimo sistemos garsas girdimas užkūrus automobilį.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-repair-1"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Padangų montavimas</h5>
                                        <p>Lietuvoje padangas reikia keisti du kartus per metus – keičiantis
                                            sezonams.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-engine"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Kuro sitemos remontas</h5>
                                        <p>Dingo trauka? Automobilis naudoja l. daug kuro? Tikėtina, kad yra kuro
                                            sistemos gedimas.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-engine"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Aušinimo sistemos remontas</h5>
                                        <p>Kaista variklis? Aušinimo skysčio temperatūra viršija 100 C? Tikėtinas
                                            termostato ar aušinimo siurblio gedimas.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-brake"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Važiuoklės remontas</h5>
                                        <p>Šarnyrai, traukės, gumos, salenblokai, spyruoklės, vairo kolonėlės ir
                                            t.t.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
                                <div className="feature-container feature-bx1">
                                    <div className="icon-lg mb-10 text-primary">
                                        <i className="flaticon-car-4"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-tilte">Variklio diržų keitimas</h5>
                                        <p>Dažniausiai keisti reikia kas 80 -100 tūkstančių kilometrų.</p>
                                        <Link to="/faq-1" className="btn-link">Plačiau</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FeatureOneSection;