import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import bg1 from "../../images/background/bg1.jpg"
import testPic1 from "../../images/testimonials/pic1.jpg"
import testPic2 from "../../images/testimonials/pic2.jpg"
import testPic3 from "../../images/testimonials/pic3.jpg"
import testPic4 from "../../images/testimonials/pic4.jpg"

// Team Content
const content = [
	{ 
		thumb: testPic1,
		name: "Milda",
		text: "Savo BMW visada patikiu šiems vyrukams ir dar neteko nusivilti. Jie viską sutvarko l.greitai ir manau kokybiškai. Kodėl taip manau? Nes po jų paslaugų mano automobilis tiesiog važiuoja, neskleidžia jokių pašalinių garsų ir po jų apžiūros galiu drasiai naudotis savo automobiliu."
	},
	{ 
		thumb: testPic2,
		name: "Vladas",
		text: "Gerai padaro savo darbą, neprirašo ko nereikia, sutvarko auto greitai ir kokybiškai, o man tik tiek ir tereikia. Beja maloniai bendrauja...",
	},
	/*{
		thumb: testPic3,
		name: "Michel Bohman",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit risus at quam hendrerit, quis egestas ligula imperdiet. Cras ultricies massa nec nulla tincidunt, vel viverra lacus ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque metus odio, pellentesque sed.",		
	},
	{ 
		thumb: testPic4,
		name: "Kalina Mollika",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit risus at quam hendrerit, quis egestas ligula imperdiet. Cras ultricies massa nec nulla tincidunt, vel viverra lacus ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque metus odio, pellentesque sed.",		
	},*/
]

class TestimonialSection extends Component{
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 2,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp2 bg-gray ovbl-dark testimonial-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix mb-2">
								<h6 className="title-ext text-primary">ATSILIEPIMAI</h6>
								<h2 className="title mb-0">KĄ SAKO KLIENTAI</h2>
							</div>
						{/*	<div className="clearfix mt-md-20">
								<Link to="#" className="btn btn-outline-primary">View All Client</Link>
							</div>*/}
						</div>
						<Slider {...settings} className="testimonial-carousel-1 slick-arrow-none">
							{content.map((item) =>(
								<div className="slider-item">								
									<div className="testimonial-bx">
										<div className="testimonial-content">
											<p>{item.text}</p>
										</div>
										<div className="client-info">
											<div className="testimonial-info">
												<h6 className="name">- {item.name}</h6>
											</div>
											<div className="testimonial-thumb">
												<img src={item.thumb} alt=""/>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</section>
				
			</>
		);
	}
}

export default TestimonialSection;