import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr1.jpg";

class ContactUs extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Kontaktai</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Pagrindinis</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Kontaktai</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="heading-bx mb-20">
								{/*<h6 className="title-ext text-primary">Kontaktinė informacija</h6>*/}
								<h2 className="title mb-0">KONTAKTINĖ INFORMACIJA</h2>
								<br/>
							</div>
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="icon-box">
										<h6 className="title"><i className="ti-map-alt"></i>Autoserviso adresas ir koordinatės</h6>
										<p>A. Juozapavičiaus pr. 19B, 45252, Kaunas</p>
										<p>Koordinatės: 54.86490, 23.94748</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="icon-box">
										<h6 className="title"><i className="ti-id-badge"></i>Email & Telefonas</h6>
										<Link to="#">info@autoservisas.net</Link>
										<p>+370(654)47643</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12">
									<div className="icon-box">
										<h6 className="title"><i className="ti-world"></i>Mus sekite</h6>
										<ul className="social-media">
											<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
											<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					
					<div className="section-area">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d574.0319061667101!2d23.9416897292532!3d54.86557007057186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e722ee8c074a5b%3A0xc8c0ed1b0c0d145d!2sA.%20Juozapavi%C4%8Diaus%20pr.%2019B%2C%2045252%20Kaunas!5e0!3m2!1slt!2slt!4v1673639486158!5m2!1slt!2slt"
							className="align-self-stretch d-flex"
							style={{width: "100%", minHeight: "450px"}} title="map" allowfullscreen></iframe>

					{/*	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.1298878182047!2d-81.38369578541523!3d30.204840081824198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e437ac927a996b%3A0x799695b1a2b970ab!2sNona+Blue+Modern+Tavern!5e0!3m2!1sen!2sin!4v1548177305546" className="align-self-stretch d-flex"
							style={{width: "100%", minHeight: "450px"}} title="map" allowfullscreen></iframe>*/}

					</div>
					
					<section className="section-area section-sp2">
						<div className="container">		
							<div className="row">
								<div className="col-lg-12 col-md-12">
									<form className="contact-form ajax-form" action="script/contact.php">
										<div className="heading-bx mb-20">
											<h6 className="title-ext text-primary">Kontaktinė forma</h6>
											<h2 className="title mb-0">Atsakysime į visas jūsų<br/> ŽINUTES</h2>
										</div>
										<div className="row">
											<div className="col-lg-4 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" required className="form-control valid-character" placeholder="Jūsų vardas"/>
													</div>
												</div>
											</div>
											<div className="col-lg-4 col-md-6">
												<div className="form-group">
													<div className="input-group"> 
														<input name="email" type="email" className="form-control" required  placeholder="Jūsų elektroninis paštas"/>
													</div>
												</div>
											</div>
											<div className="col-lg-4 col-md-12">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" required className="form-control int-value" placeholder="Jūsų telefonas"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" placeholder="Jūsų klausimas" required ></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg"> Siūsti žinutę</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ContactUs;