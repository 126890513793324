import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg1 from "../../images/background/bg1.png";
import pic1 from "../../images/about/pic1.jpg";

class AboutUsOneSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp1 bg-white" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:"no-repeat"}}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-5 mb-40">
								<div className="heading-bx mb-4">
									<h6 className="title-ext text-primary">Apie mus</h6>
									<h2 className="title">VISUS REMONTO DARBUS ATLIEKA AUKŠTOS KVALIFIKACIJOS INŽINIERIAI IR MECHANIKAI</h2>
									<p></p>
								</div>
								<Link to="/about-1" className="btn btn-primary">Apie mus</Link>
							</div>
							<div className="col-lg-7 mb-30">							
								<div className="about-media">
									<div className="media">
										<img src={pic1} alt=""/>
									</div>
									<div className="about-contact bg-primary text-white">
										<h6 className="title-ext text-white">Susisiekti</h6>
										<h3 className="number mb-15 text-white">+370(654)47643 </h3>
										<p className="mb-20">Sugedo automobilis ir jį reikia greitai ir kokybiškai suremontuoti. Kreipkitės į mus.</p>
										<Link to="/booking" className="btn btn-light">REZERVACIJA</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default AboutUsOneSection;